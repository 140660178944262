import {
  AddCircle,
  ChevronRight,
  ContentPaste,
  ExpandMore,
  MoreVert
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Chip,
  Collapse,
  darken,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  styled,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProps } from "styled-components";
import { serverTimeFormat } from "../../model/database-object.model";
import {
  NestedSetting,
  newValues,
  SettingTreeOverride,
  ValueType,
  ValueTypeColors
} from "../../model/versioned-settings.model";
import {
  addSetting,
  collapseSetting,
  duplicateSetting,
  expandSetting,
  removeSetting,
  selectEditMode,
  selectExpandedSettingIds,
  updateSetting
} from "./appSettingsSlice";
import { DescriptionField } from "./DescriptionField";
import { SettingTreeItem } from "./SettingTree";
import { getSiblings } from "./utils/getSiblings";

type SettingTreeRowProps = {
  item: SettingTreeItem;
  level: number;
  index: number;
  treeData: SettingTreeItem[];
  overrideTreeData: SettingTreeOverride[];
};

const StyledRow = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  color: theme.palette.text.secondary,
  width: "100%"
}));

const StyledSettingContent = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "85%",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

const StyledOptions = styled(Box)(() => ({
  display: "none"
}));

const SettingContainer = styled(Box)(
  ({ theme, type }: ThemeProps<any> & { index: number; type: ValueType }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "&:hover": {
      "& .icon-options": {
        display: "block"
      }
    },
    border: ` 1px solid ${theme.palette.background.default}`,
    borderLeft: `2px solid ${ValueTypeColors[type]}`,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    padding: "5px"
  })
);

const SettingField = styled(TextField)(() => ({
  "& .MuiInput-underline:before": {
    borderBottom: "none"
  },
  "& .MuiInput-input": {
    fontSize: "0.8em"
  }
}));

const StyledSettingValue = styled(TextField)(() => ({
  width: "100%",
  "& input": {
    paddingTop: "0.25rem",
    paddingBottom: "0.3rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    fontSize: "0.8em"
  }
}));

export const getNewModified = () => {
  return {
    user: localStorage.getItem("user_id")! ?? "external_user",
    modified: moment().format(serverTimeFormat)
  };
};

export default function SettingTreeRow({
  item,
  level,
  children,
  index,
  treeData,
  overrideTreeData
}: PropsWithChildren<SettingTreeRowProps>) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const editMode = useSelector(selectEditMode);
  const expandedSettingsIds = useSelector(selectExpandedSettingIds);
  const [currentSubType, setCurrentSubType] = useState<ValueType>(
    treeData.find((itemObj) => itemObj.parentId === item.id)?.type ||
      ValueType.STRING
  );

  const setIsCollapsed = (collapse: boolean) => {
    if (collapse) {
      dispatch(collapseSetting(item.id));
    } else {
      dispatch(expandSetting(item.id));
    }
  };
  const isCollapsed = !expandedSettingsIds.includes(item.id);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [typeAnchorEl, setTypeAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const typeOpen = Boolean(typeAnchorEl);

  const [anchorElSub, setAnchorElSub] = React.useState<null | HTMLElement>(
    null
  );
  const openSub = Boolean(anchorElSub);

  const { enqueueSnackbar } = useSnackbar();

  const filteredOverrides = overrideTreeData.filter(
    (override) => override.path.join(",") === item.path.join(",")
  );
  const hasOverrides = !!filteredOverrides.length;
  const multipleOverrides = filteredOverrides.length > 1;

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      enqueueSnackbar("Copied to clipboard");
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickSub = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorElSub(event.currentTarget);
  };

  const handleValue = (oldValue: any, oldType: string, newType: string) => {
    let newValue = null;

    if (oldType !== newType && !!oldValue) {
      // String to int/double
      if (
        oldType === ValueType.STRING &&
        (newType === ValueType.INT || newType === ValueType.DOUBLE)
      ) {
        if (oldValue.match("\\d.*")) {
          newValue = parseFloat(oldValue);
        }
      }

      // Int/double to string
      else if (
        (oldType === ValueType.DOUBLE || oldType === ValueType.INT) &&
        newType === ValueType.STRING &&
        !!oldValue
      ) {
        newValue = oldValue.toString();
      }

      // Int to double
      else if (
        oldType === ValueType.INT &&
        newType === ValueType.DOUBLE &&
        !!oldValue
      ) {
        newValue = parseFloat(oldValue);
      }

      // Double to int
      else if (
        oldType === ValueType.DOUBLE &&
        newType === ValueType.INT &&
        !!oldValue
      ) {
        newValue = parseInt(oldValue);
      } else {
        newValue = null;
      }
    }

    return newValue;
  };
  const handleCloseSub = (
    event: React.MouseEvent<HTMLElement>,
    option?: ValueType
  ) => {
    event.stopPropagation();

    if (option) {
      setCurrentSubType(option);

      const children = treeData.filter(
        (itemObj) => itemObj.parentId === item.id
      );

      children.forEach((item) => {
        dispatch(
          updateSetting({
            id: item.id,
            changes: {
              type: event.currentTarget.innerText,
              value: handleValue(
                item.value,
                item.type,
                event.currentTarget.innerText
              )
            }
          })
        );
      });
    }

    setAnchorElSub(null);
  };

  const handleTypeClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setTypeAnchorEl(event.currentTarget);
  };

  const handleTypeClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const { typeValue: newType } = event.currentTarget.dataset;
    const oldType = item.type;
    const oldValue = item.value;

    let newValue = newValues[newType ?? ""];

    // If type has changed
    if (newType && oldType !== newType) {
      // Check for case where value should be preserved

      // String to int/double
      if (
        oldType === ValueType.STRING &&
        (newType === ValueType.INT || newType === ValueType.DOUBLE)
      ) {
        if (oldValue.match("\\d.*")) {
          newValue = parseFloat(oldValue);
        }
      }

      // Int/double to string
      if (
        (oldType === ValueType.DOUBLE || oldType === ValueType.INT) &&
        newType === ValueType.STRING
      ) {
        newValue = oldValue.toString();
      }

      // Int to double
      if (oldType === ValueType.INT && newType === ValueType.DOUBLE) {
        newValue = parseFloat(oldValue);
      }

      // Double to int
      if (oldType === ValueType.DOUBLE && newType === ValueType.INT) {
        newValue = parseInt(oldValue);
      }

      const hasChildren =
        Array.isArray(newValue) ||
        (typeof newValue === "object" && newValue !== null);

      // Remove children elements if going from children -> no children
      if (item.hasChildren && !hasChildren) {
        const children = treeData.filter(
          (itemObj) => itemObj.parentId === item.id
        );

        for (const setting of children) {
          dispatch(removeSetting(setting.id));
        }
      } else {
        // Preserve children
        const children = treeData
          .filter((itemObj) => itemObj.parentId === item.id)
          .sort((a, b) =>
            newType === ValueType.ARRAY
              ? parseInt(a.name) - parseInt(b.name)
              : a.name.localeCompare(b.name)
          );

        for (const [index, setting] of children.entries()) {
          // If new type is array - replace setting names with index
          if (newType === ValueType.ARRAY) {
            dispatch(
              updateSetting({
                id: setting.id,
                changes: {
                  name: index.toString()
                }
              })
            );
          }
        }
      }

      dispatch(
        updateSetting({
          id: item.id,
          changes: {
            type: newType,
            value: newValue,
            hasChildren
          }
        })
      );

      setTempValue(newValue);
    }

    setTypeAnchorEl(null);
  };

  const isCollection =
    item.type === ValueType.OBJECT || item.type === ValueType.ARRAY;

  const parentItem = treeData.find((itemObj) => itemObj.id === item.parentId);
  const isParentArray = parentItem?.type === ValueType.ARRAY;

  const updateAllParents = () => {
    // Update current setting
    dispatch(
      updateSetting({
        id: item.id
      })
    );

    let currentParentId = item.parentId;

    // Get all parents of this setting
    while (currentParentId !== 0) {
      dispatch(
        updateSetting({
          id: currentParentId
        })
      );

      const parentItem = treeData.find(
        // eslint-disable-next-line no-loop-func
        (itemObj) => itemObj.id === currentParentId
      );

      currentParentId =
        parentItem && parentItem.parentId ? parentItem.parentId : 0;
    }
  };

  const initialValue =
    hasOverrides && !multipleOverrides
      ? filteredOverrides[0].value
      : item.value;

  useEffect(() => {
    setTempValue(item.value);
  }, [item.value]);

  useEffect(() => {
    setTempTag(item.tag);
  }, [item.tag]);

  //const [tempName, setTempName] = useState(item.name);
  const [tempValue, setTempValue] = useState(initialValue);
  const [tempTag, setTempTag] = useState(item.tag);

  const updateSettingField = (newValue: any, field: keyof SettingTreeItem) => {
    if (item[field] === newValue) {
      return;
    }

    dispatch(
      updateSetting({
        id: item.id,
        changes: {
          [field]: newValue
        }
      })
    );

    updateAllParents();
  };

  const addChildSetting = (type?: string) => {
    const siblings = getSiblings(treeData, item.id);

    if (isCollapsed) {
      setIsCollapsed(false);
    }

    dispatch(
      addSetting({
        treeData,
        parentId: item.id,
        name:
          item.type === ValueType.ARRAY
            ? siblings.length.toString()
            : `newSetting`,

        type: type
      })
    );

    updateAllParents();
  };

  const hideEmptyTooltips = false;

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const overrideBackgroundColor =
    index % 2 === 0
      ? darken(theme.palette.primary.main, 0.6)
      : darken(theme.palette.primary.main, 0.65);

  const normalBackgroundColor =
    index % 2 === 0
      ? theme.palette.background.paper
      : darken(theme.palette.background.paper, 0.05);

  return (
    <StyledRow data-testid={`setting-${item.id.toString()}`}>
      <Box
        width="100%"
        sx={{
          background: `linear-gradient(90deg, ${
            item.type === ValueType.ARRAY
              ? darken(ValueTypeColors[item.type], 0.4)
              : darken(ValueTypeColors[ValueType.OBJECT], 0.4)
          } 0%, transparent 100%)`,
          borderRadius: "10px",
          marginLeft: level !== 0 ? theme.spacing(4) : 0
        }}
      >
        <Tooltip
          arrow
          title={
            editMode && !hideEmptyTooltips ? (
              <DescriptionField
                updateSetting={(setting: Partial<NestedSetting>) =>
                  updateSettingField(setting.__description__, "description")
                }
                initialValue={item.description}
              />
            ) : (
              item.description
            )
          }
        >
          <SettingContainer
            theme={theme}
            index={index}
            type={item.type}
            key={`section-${item.id}`}
            sx={{
              backgroundColor: hasOverrides
                ? overrideBackgroundColor
                : normalBackgroundColor,
              transition: ".1s background-color ease-in",
              cursor:
                editMode && isParentArray
                  ? "grab"
                  : isCollection
                  ? "pointer"
                  : "auto",
              "&:hover": {
                backgroundColor: darken(normalBackgroundColor, 0.2)
              }
            }}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <StyledSettingContent>
              <Box width="35%" display="flex" alignItems="center">
                {item.hasChildren && !isMobile && (
                  <Box width="20%">
                    <IconButton
                      style={{ padding: "0.7rem" }}
                      size="small"
                      onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                      {isCollapsed ? <ChevronRight /> : <ExpandMore />}
                    </IconButton>
                  </Box>
                )}
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                  paddingX={2}
                >
                  <SettingField
                    onClick={(event) => event.stopPropagation()}
                    style={{ width: "100%", fontWeight: 600 }}
                    size="small"
                    variant="standard"
                    placeholder="Enter name"
                    disabled={isParentArray || !editMode}
                    value={item.name}
                    onChange={(event) =>
                      updateSettingField(event.target.value, "name")
                    }
                  />
                  <SettingField
                    onClick={(event) => event.stopPropagation()}
                    size="small"
                    variant="standard"
                    style={{ width: "100%", fontWeight: 200 }}
                    value={tempTag}
                    disabled={!editMode}
                    onChange={(event) => setTempTag(event.target.value)}
                    onBlur={() => updateSettingField(tempTag, "tag")}
                  />
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                width="55%"
                alignItems="start"
              >
                {!item.hasChildren ? (
                  item.type === ValueType.DOUBLE ||
                  item.type === ValueType.INT ? (
                    <StyledSettingValue
                      disabled={!editMode}
                      onClick={(event) => event.stopPropagation()}
                      value={tempValue ?? ""}
                      size="small"
                      variant="outlined"
                      type="number"
                      inputProps={{
                        step: item.type === ValueType.DOUBLE ? "0.01" : " 0"
                      }}
                      onChange={(event) => setTempValue(event.target.value)}
                      onBlur={() =>
                        updateSettingField(parseFloat(tempValue), "value")
                      }
                    />
                  ) : item.type === ValueType.BOOLEAN ? (
                    <Button
                      size="small"
                      style={{
                        backgroundColor: item.value ? "#198754" : "#dc3545",
                        borderColor: item.value ? "#198754" : "#dc3545",
                        color: "#FFF",
                        width: "100%"
                      }}
                      onClick={() => updateSettingField(!item.value, "value")}
                    >
                      {item.value?.toString() === "true" ? "True" : "False"}
                    </Button>
                  ) : item.type === ValueType.NULL ? (
                    <Chip
                      style={{
                        width: "100%"
                      }}
                      label="Null"
                      variant="outlined"
                    />
                  ) : (
                    <StyledSettingValue
                      disabled={!editMode}
                      onClick={(event) => event.stopPropagation()}
                      value={tempValue ?? ""}
                      size="small"
                      variant="outlined"
                      onChange={(event) => setTempValue(event.target.value)}
                      onBlur={() => updateSettingField(tempValue, "value")}
                    />
                  )
                ) : null}
                <small>
                  Modified by {item.user} at{" "}
                  {item.modified
                    ? moment(item.modified).format("DD/MM/YYYY HH:mm")
                    : "unknown time"}
                </small>
              </Box>
            </StyledSettingContent>

            <Box width="20%">
              <StyledOptions className="icon-options">
                {!isCollection && (
                  <Tooltip title="Copy to clipboard">
                    <Badge badgeContent={0} color="primary">
                      <IconButton
                        style={{
                          padding: "0.7rem"
                        }}
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          copyToClipboard(tempValue);
                        }}
                      >
                        <ContentPaste />
                      </IconButton>
                    </Badge>
                  </Tooltip>
                )}
                {isCollection && (
                  <Tooltip title="Add">
                    <span>
                      <IconButton
                        aria-label="add"
                        disabled={!editMode}
                        onClick={(event) => {
                          event.stopPropagation();

                          item.type === "array"
                            ? addChildSetting(currentSubType)
                            : addChildSetting();
                        }}
                        size="small"
                        style={{ padding: "0.7rem" }}
                      >
                        <AddCircle />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </StyledOptions>
            </Box>
            <Box width="10%">
              <Chip
                disabled={
                  !editMode ||
                  treeData.find((k) => k.id === item.parentId)?.type === "array"
                }
                size="small"
                label={`${item.type}`}
                clickable
                style={{
                  width: "75px",
                  backgroundColor: ValueTypeColors[item.type],
                  marginRight: "10px"
                }}
                onClick={handleTypeClick}
                icon={<ExpandMore style={{ width: "10px" }} />}
              />

              <Menu
                id="long-menu"
                anchorEl={typeAnchorEl ?? null}
                keepMounted
                open={typeOpen}
                onClose={handleTypeClose}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    width: "20ch"
                  }
                }}
              >
                {Object.values(ValueType).map((option) => (
                  <MenuItem
                    data-type-value={option}
                    key={option}
                    selected={option === item.type}
                    onClick={handleTypeClose}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {item.type === "array" && (
              <Box width="10%">
                <Chip
                  disabled={!editMode}
                  size="small"
                  label={`${currentSubType ?? "string"}`}
                  clickable
                  style={{
                    width: "75px",
                    backgroundColor: currentSubType
                      ? ValueTypeColors[currentSubType]
                      : ValueTypeColors[item.type],
                    marginRight: "10px"
                  }}
                  onClick={handleClickSub}
                  icon={<ExpandMore style={{ width: "10px" }} />}
                />

                <Menu
                  id="long-menu-sub"
                  anchorEl={anchorElSub ?? null}
                  keepMounted
                  open={openSub}
                  onClose={(event: React.MouseEvent<HTMLElement>) =>
                    handleCloseSub(event)
                  }
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                      width: "20ch"
                    }
                  }}
                >
                  {Object.values(ValueType).map((option) => (
                    <MenuItem
                      data-type-value={option}
                      key={option}
                      selected={option === currentSubType}
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        handleCloseSub(event, option);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}

            <Box width="7%">
              <IconButton
                data-testid={`${item.id.toString()}-more-options`}
                aria-label="more-options"
                size="small"
                style={{ padding: "0.7rem" }}
                onClick={handleClick}
              >
                <MoreVert />
              </IconButton>

              <Menu
                anchorEl={anchorEl ?? null}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
              >
                {isCollection && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      addChildSetting();
                      handleClose(event);
                    }}
                    disabled={!editMode}
                  >
                    Add
                  </MenuItem>
                )}
                <MenuItem
                  disabled={!editMode}
                  onClick={(event) => {
                    event.stopPropagation();
                    dispatch(duplicateSetting({ treeData, setting: item }));
                    handleClose(event);
                  }}
                >
                  Duplicate
                </MenuItem>
                {!isCollection && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      copyToClipboard(tempValue);
                      handleClose(event);
                    }}
                  >
                    Copy value to clipboard
                  </MenuItem>
                )}
                <Divider />
                <MenuItem
                  disabled={!editMode}
                  onClick={(event) => {
                    event.stopPropagation();
                    const oldParentId = item.parentId;
                    const oldItemId = item.id;
                    dispatch(removeSetting(item.id));
                    const siblings = getSiblings(treeData, oldParentId);

                    if (isParentArray) {
                      const updatedSettings = siblings
                        .filter((setting) => setting.id !== oldItemId)
                        .map((setting, index) => ({
                          ...setting,
                          name: index.toString()
                        }));

                      for (const setting of updatedSettings) {
                        dispatch(
                          updateSetting({
                            id: setting.id,
                            changes: {
                              ...setting
                            }
                          })
                        );
                      }
                    }
                    handleClose(event);
                  }}
                >
                  Delete
                </MenuItem>
                <MenuItem
                  disabled={!editMode}
                  onClick={(event) => {
                    event.stopPropagation();

                    const children = treeData.filter(
                      (itemObj) => itemObj.parentId === item.id
                    );

                    for (const setting of children) {
                      dispatch(removeSetting(setting.id));
                    }

                    handleClose(event);
                  }}
                >
                  Delete setting content
                </MenuItem>
              </Menu>
            </Box>
          </SettingContainer>
        </Tooltip>
        {isCollection && (
          <Collapse in={!isCollapsed}>{!isCollapsed && children}</Collapse>
        )}
      </Box>
    </StyledRow>
  );
}
