import { SettingTreeItem } from "../features/VersionedAppSettings/SettingTree";
import { IDatabaseObject } from "./database-object.model";

export interface IVersionedAppSettings extends IDatabaseObject {
  nested_settings: NestedSettings;
  name: string;
}

export interface INormVersionedAppSettings extends IDatabaseObject {
  nested_settings: SettingTreeItem[];
  name: string;
}

export enum OverrideType {
  COUNTRY = "country"
}

interface DeletedPath {
  path: string[];
  user: string;
  modified: string;
}

interface NestedOverride {
  path: string[];
  nested_settings: NestedSettings;
}

interface NormNestedOverride {
  path: string[];
  nested_settings: SettingTreeOverride[];
}

export interface IVersionedAppSettingsOverrides extends IDatabaseObject {
  versioned_app_settings_id: string;
  override_type: OverrideType;
  override_key: string;
  deleted_paths: DeletedPath[];
  nested_overrides: NestedOverride[];
}

export interface INormVersionedAppSettingsOverrides extends IDatabaseObject {
  versioned_app_settings_id: string;
  override_type: OverrideType;
  override_key: string;
  deleted_paths: DeletedPath[];
  nested_overrides: NormNestedOverride[];
}

export type SettingTreeOverride = {
  override_type: OverrideType;
  override_key: string;
  delete?: boolean;
} & SettingTreeItem;

export type CountryGroups = Record<string, CountryGroup>;

export type CountryGroup = string[];

export type CountryGroupsConfig = {
  groups: CountryGroups;
  order: string[];
};

export type CountryOption = { label: string; value: string };

export interface NestedSettings {
  [id: string]: NestedSetting;
}

export enum ValueType {
  STRING = "string",
  OBJECT = "object",
  ARRAY = "array",
  INT = "int",
  DOUBLE = "double",
  BOOLEAN = "boolean",
  NULL = "null"
}

export const newValues: Record<string, any> = {
  string: "",
  int: 0,
  double: 0.0,
  null: null,
  object: {},
  array: [],
  boolean: false
};

export const ValueTypeColors = {
  [ValueType.OBJECT]: "#6f42c1",
  [ValueType.ARRAY]: "#fd7e14",
  [ValueType.STRING]: "#198754",
  [ValueType.INT]: "#0d6efd",
  [ValueType.DOUBLE]: "#0aa2c0",
  [ValueType.BOOLEAN]: "#ffc107",
  [ValueType.NULL]: "#d63384"
};

type SettingValueMap = {
  [ValueType.STRING]: string;
  [ValueType.OBJECT]: { [id: string]: NestedSetting };
  [ValueType.ARRAY]: NestedSetting[];
  [ValueType.INT]: number;
  [ValueType.DOUBLE]: number;
  [ValueType.BOOLEAN]: boolean;
  [ValueType.NULL]: null;
};

export type SettingValue<T extends ValueType> = T extends keyof SettingValueMap
  ? SettingValueMap[T]
  : never;

export type NestedSetting<T extends ValueType = ValueType> = {
  __value__: SettingValue<T>;
  __user__: string;
  __type__: T;
  __modified__: string;
  __tag__: string;
  __description__: string;
  __valuehash__?: string;
};
